<template>
    <b-card style="height: calc(30vh - 28px)">
        <b-card-title> 运行优化 </b-card-title>
        <b-button
            class="btn-icon rounded-circle"
            variant="flat-primary"
            style="position: absolute; top: 0.5rem; right: 0.5rem"
        >
            <b-icon-three-dots-vertical />
        </b-button>

        <b-tabs vertical nav-wrapper-class="nav-vertical">
            <b-tab title="夏季">
                <vue-perfect-scrollbar style="height: calc(30vh - 112px)">
                    <b-table
                        :fields="tableColumns"
                        :items="strategySummer"
                        class="w-100 h-100"
                        small
                        striped
                    >
                        <template #cell(param_name)="data">
                            <div>
                                {{ data.item.param_name }}
                            </div>
                        </template>
                        <template #cell(change)="data">
                            <feather-icon
                                v-if="
                                    data.item.cur_value !== data.item.rec_value
                                "
                                :class="
                                    data.item.cur_value > data.item.rec_value
                                        ? 'text-danger'
                                        : 'text-success'
                                "
                                :icon="
                                    data.item.cur_value > data.item.rec_value
                                        ? 'ArrowDownIcon'
                                        : 'ArrowUpIcon'
                                "
                            />
                            <feather-icon
                                v-else
                                class="text-primary"
                                icon="MinusIcon"
                            />
                        </template>
                    </b-table>
                </vue-perfect-scrollbar>
            </b-tab>

            <b-tab title="冬季">
                <vue-perfect-scrollbar style="height: calc(30vh - 112px)">
                    <b-table
                        :fields="tableColumns"
                        :items="strategyWinter"
                        class="w-100 h-100"
                        small
                        striped
                    >
                        <template #cell(param_name)="data">
                            {{ data.item.param_name }}
                        </template>
                        <template #cell(change)="data">
                            <feather-icon
                                v-if="
                                    data.item.cur_value !== data.item.rec_value
                                "
                                :class="
                                    data.item.cur_value > data.item.rec_value
                                        ? 'text-danger'
                                        : 'text-success'
                                "
                                :icon="
                                    data.item.cur_value > data.item.rec_value
                                        ? 'ArrowDownIcon'
                                        : 'ArrowUpIcon'
                                "
                            />
                            <feather-icon
                                v-else
                                class="text-primary"
                                icon="MinusIcon"
                            />
                        </template>
                    </b-table>
                </vue-perfect-scrollbar>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
import { reactive, toRefs } from "@vue/composition-api";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
    name: "Optimization",
    components: {
        VuePerfectScrollbar,
    },
    setup(props) {
        const event = reactive({
            tableColumns: [
                {
                    label: "节能运行参数",
                    key: "param_name",
                },
                {
                    label: "当前值",
                    key: "cur_value",
                },
                {
                    label: "推荐值",
                    key: "rec_value",
                },
                {
                    label: "变化",
                    key: "change",
                },
            ],
            strategySummer: [
                {
                    param_name: "冷冻水出水温度 (℃)",
                    cur_value: "7.2",
                    rec_value: "8",
                    change: "",
                },
                {
                    param_name: "制冷机开启台数",
                    cur_value: "1",
                    rec_value: "1",
                    change: "",
                },
                {
                    param_name: "冷冻水泵频率 (Hz)",
                    cur_value: "45",
                    rec_value: "42",
                    change: "",
                },
                {
                    param_name: "冷却水泵频率 (Hz)",
                    cur_value: "47",
                    rec_value: "42",
                    change: "",
                },
                {
                    param_name: "冷却塔开启台数",
                    cur_value: "1",
                    rec_value: "1",
                    change: "",
                },
                {
                    param_name: "冷却塔风机频率 (Hz)",
                    cur_value: "35",
                    rec_value: "30",
                    change: "",
                },
                {
                    param_name: "开机时间",
                    cur_value: "8:00",
                    rec_value: "8:30",
                    change: "",
                },
                {
                    param_name: "关机时间",
                    cur_value: "18:00",
                    rec_value: "17:30",
                    change: "",
                },
            ],
            strategyWinter: [
                {
                    param_name: "冷凝侧出水温度 (℃)",
                    cur_value: "45",
                    rec_value: "42",
                    change: "",
                },
                {
                    param_name: "冷凝侧回水温度 (℃)",
                    cur_value: "40",
                    rec_value: "38",
                    change: "",
                },
                {
                    param_name: "蒸发侧出水温度 (℃)",
                    cur_value: "10",
                    rec_value: "9",
                    change: "",
                },
                {
                    param_name: "蒸发侧回水温度 (℃)",
                    cur_value: "12",
                    rec_value: "13",
                    change: "",
                },
                {
                    param_name: "冷却水泵频率 (Hz)",
                    cur_value: "45",
                    rec_value: "43.5",
                    change: "",
                },
                {
                    param_name: "室内侧水泵频率 (Hz)",
                    cur_value: "50",
                    rec_value: "48",
                    change: "",
                },
                {
                    param_name: "冷机开启台数",
                    cur_value: "2",
                    rec_value: "2",
                    change: "",
                },
                {
                    param_name: "开机时间",
                    cur_value: "8:00",
                    rec_value: "7:50",
                    change: "",
                },
                {
                    param_name: "关机时间",
                    cur_value: "18:00",
                    rec_value: "17:30",
                    change: "",
                },
            ],
        });

        return {
            ...toRefs(event),
        };
    },
};
</script>

<style scoped></style>
