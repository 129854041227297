<template>
	<b-card>
		<b-row>
			<b-col class="text-center" cols="4">
				<b-avatar class="mb-1" size="36" variant="light-danger">
					<feather-icon icon="HexagonIcon" size="18" />
				</b-avatar>
				<b-card-text>
					<h4 class="my-0">
						<span class="font-digit">{{ projectInfo.building_area || "N/A" }}</span>
						<span class="text-muted font-small-2"> m<sup>2</sup></span>
					</h4>
				</b-card-text>
			</b-col>

			<b-col class="text-center" cols="4">
				<b-avatar class="mb-1" size="36" variant="light-success">
					<feather-icon icon="LayersIcon" size="18" />
				</b-avatar>
				<b-card-text>
					<h4 class="my-0">
						<span class="font-digit">{{ projectInfo.building_level || "N/A" }}</span>
						<span class="text-muted font-small-2"> 层</span>
					</h4>
				</b-card-text>
			</b-col>

			<b-col class="text-center" cols="4">
				<b-avatar class="mb-1" size="36" variant="light-warning">
					<feather-icon icon="ClockIcon" size="18" />
				</b-avatar>
				<b-card-text>
					<h4 class="my-0">
						<span class="font-digit">
							{{ projectInfo.building_time ? momentJs(projectInfo.building_time).format("Y") : "N/A" }}
						</span>
						<span class="text-muted font-small-2"> 年</span>
					</h4>
				</b-card-text>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
	import { computed, reactive, toRefs } from "@vue/composition-api";
	import { momentJs } from "@/libs/utils/moment";

	export default {
		name: "ParamsLeft",
		props: {
			project_info: [Object],
		},
		setup(props) {
			const event = reactive({
				projectInfo: computed(() => {
					return props.project_info;
				}),
			});
			return {
				...toRefs(event),
				momentJs,
			};
		},
	};
</script>

<style scoped></style>
