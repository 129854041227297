import { new_nanoid } from "@/libs/utils/nanoId";

export function iframeSrc(src) {
	const unique_str = new_nanoid();
	if (src.indexOf("?") > -1) {
		src += "&noCache=" + unique_str;
	} else {
		src += "?noCache=" + unique_str;
	}
	return src;
}
